import AuthComponentBase from '@/shared/application/auth-component-base';
import { Component, Prop } from 'vue-property-decorator';
import draggable from 'vuedraggable';

@Component({
    components: {
        draggable,
    }
})
export default class ColumnSelectDialog extends AuthComponentBase {
    @Prop({ default: [] }) availableColumns: any;
    @Prop({ default: [] }) columns: any;
    @Prop({ default: false }) readonly show: boolean;

    newAvailableColumns: any[] = [];
    newColumns: any[] = [];

    getColumns() {
        this.newColumns.forEach(item => item.text = item.value !== 'id' ? this.t(item.value) : item.value);
        return this.newColumns;
    }

    getAvailableColumns() {
        this.newAvailableColumns.forEach(item => item.text = item.value !== 'id' ? this.t(item.value) : item.value);
        return this.newAvailableColumns;
    }

    mounted() {
        this.newColumns = [...this.columns]; // clone the array

        // add the unused columns to the newAvailableColumns list
        const tempAllColumns = [...this.columns].concat(this.availableColumns.filter(p => p.text !== 'Translation'));
        tempAllColumns.forEach(x => {
            this.columns.filter(a => a.text === x.text).length > 0 ? null : this.newAvailableColumns.push(x)
        });
    }

    addColumn(element, i) {
        this.newAvailableColumns.splice(i, 1);
        this.newColumns.push(element);
    }

    removeColumn(element, i) {
        this.newColumns.splice(i, 1);
        this.newAvailableColumns.push(element);
    }

    close() {
        this.$emit('completed', null);
    }

    save() {
        this.$emit('completed', this.newColumns);
    }
}